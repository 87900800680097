import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import loadable from "@loadable/component"
import LazyLoad from "react-lazy-load"
import { dsaCoursePath } from "../data/coursePath"
import SocialMedia from "../components/social-media"

const ContactForm = loadable(() => import("../components/contactForm"))
const NewsletterStrip = loadable(() => import("../components/newsletterStrip"))
const CoursesAndBlogs = loadable(() => import("../components/CoursesAndBlogs"))
const Footer3 = loadable(() => import("../components/footer3"))
const Scroll = loadable(() => import("../components/scroll"))

// Fisher-Yates shuffle algorithm
function shuffleArray(array) {
  const newArray = [...array]
  let currentIndex = newArray.length,
    temporaryValue,
    randomIndex

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1
    temporaryValue = newArray[currentIndex]
    newArray[currentIndex] = newArray[randomIndex]
    newArray[randomIndex] = temporaryValue
  }

  return newArray
}

export const BlogPostTemplate = ({
  posttitle,
  author,
  reviewer,
  tags,
  seotitle,
  content,
  description,
  path,
  similarBlogs,
}) => {
  const authorMap = {
    "Enjoy Algorithms": {
      authorName: "EnjoyAlgorithms Team",
      authorLink: "https://www.linkedin.com/company/enjoy-algorithms",
    },
    "Shubham Gautam": {
      authorName: "Shubham Gautam",
      authorLink: "https://www.linkedin.com/in/shubhamgautamsuper30/",
    },

    "Ankit Nishad": {
      authorName: "Ankit Nishad",
      authorLink: "https://www.linkedin.com/in/ankit-n-b41421137/",
    },

    Prasanna: {
      authorName: "Prasanna Bartakke",
      authorLink: "https://www.linkedin.com/in/prasannabiitm/",
    },

    "Shashank Gupta": {
      authorName: "Shashank Gupta",
      authorLink: "https://www.linkedin.com/in/shashank47/",
    },

    "Ravish Raj": {
      authorName: "Ravish Raj",
      authorLink: "https://www.linkedin.com/in/ravishkumar2/",
    },

    "Navtosh Kumar": {
      authorName: "Navtosh Kumar",
      authorLink: "https://www.linkedin.com/in/navtosh-kumar/",
    },

    "Suyash Namdeo": {
      authorName: "Suyash Namdeo",
      authorLink: "https://www.linkedin.com/in/suyash-namdeo-433109151/",
    },

    Harshit: {
      authorName: "Harshit Agarwal",
      authorLink: "https://www.linkedin.com/in/harshit-agarwal-a340b3193/",
    },

    "Prabal Dutta": {
      authorName: "Prabal Dutta",
      authorLink: "https://www.linkedin.com/in/prabal-bijoy-dutta-711391146",
    },

    "Chiranjeev Kumar": {
      authorName: "Chiranjeev Kumar",
      authorLink: "https://www.linkedin.com/in/kumarchiranjeev/",
    },

    "Mahendra Chouhan": {
      authorName: "Mahendra Chouhan",
      authorLink: "https://www.linkedin.com/in/mchouhanofficial/",
    },

    "Nimit Jain": {
      authorName: "Nimit Jain",
      authorLink: "https://www.linkedin.com/in/nimit-jain-831b24190/",
    },
  }

  const reviewerMap = {
    "Shubham Gautam": {
      reviewerName: "Shubham Gautam",
      reviewerLink: "https://www.linkedin.com/in/shubhamgautamsuper30/",
    },
    "Mahendra Chouhan": {
      reviewerName: "Mahendra Chouhan",
      reviewerLink: "https://www.linkedin.com/in/mchouhanofficial/",
    },
    "Ravish Raj": {
      reviewerName: "Ravish Raj",
      reviewerLink: "https://www.linkedin.com/in/ravishkumar2/",
    },

    "Chiranjeev Kumar": {
      reviewerName: "Chiranjeev Kumar",
      reviewerLink: "https://www.linkedin.com/in/kumarchiranjeev/",
    },

    "Keshav Nandan": {
      reviewerName: "Keshav Nandan",
      reviewerLink: "https://www.linkedin.com/in/keshav-nandan/",
    },

    "Enjoy Algorithms": {
      reviewerName: "EnjoyAlgorithms Team",
      reviewerLink: "https://www.linkedin.com/company/enjoy-algorithms",
    },
  }

  const authorName = authorMap[String(author)]?.authorName || null
  const authorLink = authorMap[String(author)]?.authorLink || null
  const reviewerName = reviewerMap[String(reviewer)]?.reviewerName || null
  const reviewerLink = reviewerMap[String(reviewer)]?.reviewerLink || null

  let currentIndex
  let currentCoursePath
  const pathIndexMap = {}

  currentCoursePath = dsaCoursePath

  currentCoursePath.forEach((blog, index) => {
    pathIndexMap[blog.path] = index
  })

  currentIndex = pathIndexMap[path]

  // Get the previous and next blog paths based on the current index
  const previousPath =
    currentIndex > 0 ? currentCoursePath[currentIndex - 1].path : null
  const nextPath =
    currentIndex < currentCoursePath.length - 1
      ? currentCoursePath[currentIndex + 1].path
      : null

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>{seotitle}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          property="og:description"
          content={description}
        />
        <meta name="title" property="og:title" content={seotitle} />
        <meta name="author" content={author} />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>

      <div className="bg-light-white px-5 sm:px-7 md:px-8 lg:px-10 xl:px-12">
        <div className="bg-light-white pt-5 md:pt-8 pb-8 mx-auto sm:max-w-xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
          <div className="mx-auto">
            <h1 className="mt-2 tracking-wider select-none text-xl md:text-2xl xl:text-3xl 2xl:text-4xl leading-7 sm:text-center text-gray-900">
              {posttitle}
            </h1>
          </div>
          <div
            className="mt-4 sm:mt-6 tracking-wider select-none max-w-none prose prose xl:prose-lg 2xl:prose-xl"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>

          <LazyLoad>
            <div className="mt-6 text-sm md:text-base 2xl:text-lg tracking-wider flex justify-between">
              {previousPath && (
                <Link to={previousPath}>
                  <div className="zz-30 shadow bg-new-purple text-white hover:bg-white hover:text-new-purple px-4 py-2 rounded transition duration-150">
                    Prev Chapter
                  </div>
                </Link>
              )}

              {nextPath && (
                <Link to={nextPath}>
                  <div className="zz-30 shadow bg-new-green text-white hover:bg-white hover:text-new-green px-4 py-2 rounded transition duration-150">
                    Next Chapter
                  </div>
                </Link>
              )}
            </div>
          </LazyLoad>

          <LazyLoad>
            <div className="ml-2 mt-6 grid gap-4 sm:flex justify-between">
              {author ? (
                <a
                  href={authorLink}
                  target="_blank"
                    rel="noopener noreferrer"
                  className="text-gray-900 text-sm md:text-base 2xl:text-lg"
                >
                  <div className="flex">
                    <p className="tracking-wider leading-5">Author:</p>
                    <p className="ml-2 mr-1 tracking-wider text-gray-600 underline leading-5 hover:text-new-blue">
                      {authorName}
                    </p>
                  </div>
                </a>
              ) : null}

              {reviewer ? (
                <a
                  href={reviewerLink}
                  target="_blank"
                    rel="noopener noreferrer"
                  className="text-sm md:text-base 2xl:text-lg text-gray-900 sm:mt-0"
                >
                  <div className="flex">
                    <p className="tracking-wider leading-5 ">Reviewer:</p>
                    <p className="ml-2 mr-1 text-gray-600 underline tracking-wider leading-5 hover:text-new-blue">
                      {reviewerName}
                    </p>
                  </div>
                </a>
              ) : null}
            </div>
          </LazyLoad>

          <LazyLoad>
            <div className="sm:flex sm:justify-between">
              <div className="flex mt-6">
                <p className="mr-2 ml-2 mt-1 tracking-wider text-sm md:text-base 2xl:text-lg text-gray-900 ">
                  Share on:
                </p>
                <div className="mr-1 icon-container transform transition-transform duration-200 ease-in-out hover:scale-125">
                  <a
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.enjoyalgorithms.com${path}&text=${posttitle}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="32px"
                      height="32px"
                    >
                      <path
                        fill="#0288D1"
                        d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                      />
                      <path
                        fill="#FFF"
                        d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                      />
                    </svg>
                  </a>
                </div>

                <div className="ml-2 mr-1 icon-container transform transition-transform duration-200 ease-in-out hover:scale-125">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://twitter.com/intent/tweet?url=https://www.enjoyalgorithms.com${path}&text=${posttitle}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="36px"
                      height="36px"
                    >
                      <path
                        fill="#03A9F4"
                        d="M42,12.429c-1.323,0.586-2.746,0.977-4.247,1.162c1.526-0.906,2.7-2.351,3.251-4.058c-1.428,0.837-3.01,1.452-4.693,1.776C34.967,9.884,33.05,9,30.926,9c-4.08,0-7.387,3.278-7.387,7.32c0,0.572,0.067,1.129,0.193,1.67c-6.138-0.308-11.582-3.226-15.224-7.654c-0.64,1.082-1,2.349-1,3.686c0,2.541,1.301,4.778,3.285,6.096c-1.211-0.037-2.351-0.374-3.349-0.914c0,0.022,0,0.055,0,0.086c0,3.551,2.547,6.508,5.923,7.181c-0.617,0.169-1.269,0.263-1.941,0.263c-0.477,0-0.942-0.054-1.392-0.135c0.94,2.902,3.667,5.023,6.898,5.086c-2.528,1.96-5.712,3.134-9.174,3.134c-0.598,0-1.183-0.034-1.761-0.104C9.268,36.786,13.152,38,17.321,38c13.585,0,21.017-11.156,21.017-20.834c0-0.317-0.01-0.633-0.025-0.945C39.763,15.197,41.013,13.905,42,12.429"
                      />
                    </svg>
                  </a>
                </div>
                <div className="ml-2 mr-1 icon-container transform transition-transform duration-200 ease-in-out hover:scale-125">
                  <div
                    className="fb-share-button"
                    data-href="https://developers.facebook.com/docs/plugins/"
                    data-layout="button_count"
                    data-size="small"
                  >
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.facebook.com/sharer/sharer.php?u=https://www.enjoyalgorithms.com${path}&text=${posttitle}`}
                      className="fb-xfbml-parse-ignore"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="32px"
                        height="32px"
                      >
                        <path
                          fill="#039be5"
                          d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                        />
                        <path
                          fill="#fff"
                          d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="ml-2 -mt-1 icon-container transform transition-transform duration-200 ease-in-out hover:scale-125">
                  <a
                    href={`https://api.whatsapp.com/send?text=${posttitle}: https://www.enjoyalgorithms.com${path}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="40px"
                      height="40px"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    >
                      <path
                        fill="#40c351"
                        d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                      />
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="sm:flex">
                <SocialMedia />
              </div>
            </div>
          </LazyLoad>

          <LazyLoad>
            <div className="flex flex-wrap content-center mt-4">
              {tags &&
                tags.map((tag, index) => (
                  <Link
                    key={index}
                    to={`/tags/${tag}/`}
                    className="items-center m-2 shadow rounded border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white tracking-wider px-3 py-1 text-sm sm:text-base 2xl:text-lg leading-6 transition duration-150"
                  >
                    {tag}
                  </Link>
                ))}
            </div>
          </LazyLoad>
          <ContactForm />
        </div>

        {similarBlogs && similarBlogs.length ? (
          <div className="mx-auto mt-6">
            <h3 className="px-1 mb-2 tracking-wider text-xl lg:text-2xl 2xl:text-3xl text-center text-gray-900">
              More from EnjoyAlgorithms
            </h3>
            <div className="mt-6 lg:mt-8">
              <div className="mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">
                <LazyLoad>
                  <div className="grid h-full gap-8 lg:gap-10 md:grid-cols-2 mt-4">
                    {shuffleArray(similarBlogs)
                      .slice(0, 6)
                      .map(({ node: post }) => (
                        <Link to={post.frontmatter.path} key={post.id}>
                          <article
                            className={`h-full ${
                              post.frontmatter.featuredpost
                                ? "is-featured"
                                : "bg-white rounded"
                            }`}
                          >
                            <div className="flex flex-col h-full justify-between p-6 rounded-2xl shadow-md">
                              <div className="mt-3 tracking-wider text-lg xl:text-xl 2xl:text-2xl leading-6 text-gray-900">
                                {post.frontmatter.seotitle}

                                <p className="mt-3 leading-relaxed tracking-wider text-xs 2xl:text-sm text-gray-600">
                                  {post.frontmatter.description}
                                </p>
                              </div>
                              <div className="items-center mt-3">
                                <button
                                  className="readmore-button"
                                  type="submit"
                                >
                                  <div className="flex tracking-wider shadow text-base 2xl:text-lg px-4 py-2 rounded border border-new-green bg-white text-new-green hover:bg-new-green hover:text-white transition duration-150">
                                    <p>Read More</p>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </article>
                        </Link>
                      ))}
                  </div>
                </LazyLoad>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <Scroll showBelow={250} />

        <div className="mx-auto sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl">

          <div className="mt-12 mb-2 ">
            <h3 className="tracking-wider mx-auto w-64 sm:w-auto mb-6 px-4 text-xl lg:text-2xl 2xl:text-3xl text-center text-gray-900">
              Self-paced Courses and Blogs
            </h3>
            <CoursesAndBlogs />
          </div>
          <NewsletterStrip />
        </div>

        <div className="bg-light-white mt-4">
          <Footer3 />
        </div>
      </div>
    </div>
  )
}

const BlogPost = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, id } = markdownRemark
  const similar = allMarkdownRemark.edges
  // console.log(html)
  return (
    <BlogPostTemplate
      posttitle={frontmatter.title}
      seotitle={frontmatter.seotitle}
      author={frontmatter.author}
      reviewer={frontmatter.reviewer}
      tags={frontmatter.tags}
      path={frontmatter.path}
      content={html}
      description={frontmatter.description}
      similarBlogs={similar}
      identifierid={id}
    />
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($path: String!, $id: String!, $tags: [String]) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        author
        description
        path
        reviewer
        tags
        title
        seotitle
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: $tags }, visibility: { eq: true } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          frontmatter {
            description
            path
            seotitle
          }
          id
        }
      }
    }
  }
`
