export const dsaCoursePath = [
  {
    path: "/blog/why-should-we-learn-data-structure-and-algorithms",
  },
  {
    path: "/blog/top-10-learning-challenges-in-data-structure-and-algorithms",
  },
  {
    path: "/blog/how-to-develop-algorithmic-thinking-in-data-structure-and-algorithms",
  },
  {
    path: "/blog/step-by-step-guidance-to-master-data-structure-and-algorithms-for-coding-interview",
  },
  {
    path: "/blog/step-by-step-learning-plan-for-interview-success",
  },
  {
    path: "/blog/problem-solving-approaches-in-data-structures-and-algorithms",
  },
  {
    path: "/blog/how-to-get-started-with-competitive-programming",
  },
  {
    path: "/blog/steps-of-problem-solving-for-cracking-the-coding-interview",
  },
  {
    path: "/blog/tips-and-motivation-to-master-problem-solving",
  },
  {
    path: "/blog/introduction-to-algorithms",
  },
  {
    path: "/blog/introduction-to-data-structures",
  },
  {
    path: "/blog/time-complexity-analysis-in-data-structure-and-algorithms",
  },
  {
    path: "/blog/fundamentals-of-loop-in-programming-and-algorithms",
  },
  {
    path: "/blog/time-complexity-analysis-of-loop-in-programming",
  },
  {
    path: "/blog/recursion-explained-how-recursion-works-in-programming",
  },
  {
    path: "/blog/time-complexity-analysis-of-recursion-in-programming",
  },
  {
    path: "/blog/difference-between-iteration-and-recursion",
  },
  {
    path: "/blog/divide-and-conquer",
  },
  {
    path: "/blog/number-of-buildings-facing-sun",
  },
  {
    path: "/blog/leaders-in-an-array",
  },
  {
    path: "/blog/valid-mountain-array",
  },
  {
    path: "/blog/roman-to-integer",
  },
  {
    path: "/blog/find-the-minimum-and-maximum-value-in-an-array",
  },
  {
    path: "/blog/find-equilibrium-index-of-an-array",
  },
  {
    path: "/blog/product-of-array-except-self",
  },
  {
    path: "/blog/print-matrix-in-spiral-order",
  },
  {
    path: "/blog/rotate-a-matrix-by-90-degrees-in-an-anticlockwise-direction",
  },
  {
    path: "/blog/binary-search-algorithm",
  },
  {
    path: "/blog/find-a-fixed-point-in-a-given-array",
  },
  {
    path: "/blog/find-maximum-in-first-increasing-and-then-decreasing-array",
  },
  {
    path: "/blog/first-and-last-positions-of-element-in-sorted-array",
  },
  {
    path: "/blog/minimum-in-sorted-and-rotated-array",
  },
  {
    path: "/blog/search-in-a-row-wise-sorted-2d-matrix",
  },
  {
    path: "/blog/median-of-two-sorted-arrays",
  },
  {
    path: "/blog/find-row-with-maximum-number-of-1s",
  },
  {
    path: "/blog/why-should-we-learn-sorting-algorithms",
  },
  {
    path: "/blog/introduction-to-sorting-bubble-sort-selection-sort-and-insertion-sort",
  },
  {
    path: "/blog/merge-sort-algorithm",
  },
  {
    path: "/blog/quick-sort-algorithm",
  },
  {
    path: "/blog/lower-bound-of-comparison-sorting",
  },
  {
    path: "/blog/counting-sort",
  },
  {
    path: "/blog/comparison-of-sorting-algorithms",
  },
  {
    path: "/blog/sort-an-array-of-0s-1s-and-2s",
  },
  {
    path: "/blog/sort-an-array-in-a-waveform",
  },
  {
    path: "/blog/move-all-the-zeroes-to-the-end",
  },
  {
    path: "/blog/remove-duplicates-from-sorted-array",
  },
  {
    path: "/blog/container-with-most-water",
  },
  {
    path: "/blog/trapping-rain-water",
  },
  {
    path: "/blog/longest-substring-without-repeating-characters",
  },
  {
    path: "/blog/count-distinct-elements-in-every-window",
  },
  {
    path: "/blog/max-consecutive-ones",
  },
  {
    path: "/blog/max-consecutive-ones-II",
  },
  {
    path: "/blog/n-repeated-element-in-2n-size-array",
  },
  {
    path: "/blog/introduction-to-hashing-and-hash-table",
  },
  {
    path: "/blog/introduction-to-hash-functions",
  },
  {
    path: "/blog/check-equal-arrays",
  },
  {
    path: "/blog/most-frequent-element-in-array",
  },
  {
    path: "/blog/largest-subarray-with-zero-sum",
  },
  {
    path: "/blog/longest-consecutive-sequence",
  },
  {
    path: "/blog/valid-anagram",
  },
  {
    path: "/blog/sort-characters-by-frequency",
  },
  {
    path: "/blog/first-missing-positive",
  },
  {
    path: "/blog/fizz-buzz-problem",
  },
  {
    path: "/blog/check-for-pair-in-an-array-with-a-given-sum",
  },
  {
    path: "/blog/triplet-with-zero-sum",
  },
  {
    path: "/blog/intersection-of-unsorted-arrays",
  },
  {
    path: "/blog/check-whether-array-is-subset-of-another-array",
  },
  {
    path: "/blog/maximum-difference-between-two-elements",
  },
  {
    path: "/blog/find-the-maximum-j-i-in-array",
  },
  {
    path: "/blog/find-the-majority-element-in-an-array",
  },
  {
    path: "/blog/count-the-number-of-possible-triangles",
  },
  {
    path: "/blog/introduction-to-arrays",
  },
  {
    path: "/blog/dynamic-array",
  },
  {
    path: "/blog/find-middle-of-linked-list",
  },
  {
    path: "/blog/reverse-linked-list",
  },
  {
    path: "/blog/remove-nth-node-from-list-end",
  },
  {
    path: "/blog/intersection-of-sorted-linked-lists",
  },
  {
    path: "/blog/swap-list-nodes-in-pairs",
  },
  {
    path: "/blog/detect-loop-in-linked-list",
  },
  {
    path: "/blog/sort-linked-list-using-insertion-sort",
  },
  {
    path: "/blog/stack-data-structure",
  },
  {
    path: "/blog/application-of-stack-data-structure-in-programming",
  },
  {
    path: "/blog/sort-stack-using-temporary-stack",
  },
  {
    path: "/blog/find-next-greater-element",
  },
  {
    path: "/blog/queue-using-stacks",
  },
  {
    path: "/blog/stack-using-queues",
  },
  {
    path: "/blog/check-for-balanced-parentheses-in-expression",
  },
  {
    path: "/blog/min-stack-problem",
  },
  {
    path: "/blog/introduction-to-binary-tree",
  },
  {
    path: "/blog/binary-tree-traversals-preorder-inorder-postorder",
  },
  {
    path: "/blog/iterative-binary-tree-traversals-using-stack",
  },
  {
    path: "/blog/level-order-traversal-of-binary-tree",
  },
  {
    path: "/blog/find-height-of-a-binary-tree",
  },
  {
    path: "/blog/min-depth-of-binary-tree",
  },
  {
    path: "/blog/merge-binary-tree",
  },
  {
    path: "/blog/left-view-of-binary-tree",
  },
  {
    path: "/blog/introduction-to-binary-search-tree",
  },
  {
    path: "/blog/searching-in-binary-search-tree",
  },
  {
    path: "/blog/insertion-in-binary-search-tree",
  },
  {
    path: "/blog/inorder-successor-in-binary-search-tree",
  },
  {
    path: "/blog/inorder-predecessor-binary-search-tree",
  },
  {
    path: "/blog/introduction-to-heap-data-structure",
  },
  {
    path: "/blog/find-the-kth-smallest-element-in-an-array",
  },
  {
    path: "/blog/avl-tree-data-structure",
  },
  {
    path: "/blog/introduction-to-trie-data-structure",
  },
  {
    path: "/blog/binary-indexed-tree",
  },
  {
    path: "/blog/segment-tree-data-structure-part1",
  },
  {
    path: "/blog/segment-trees-data-structure-part-2",
  },
  {
    path: "/blog/implement-least-recently-used-cache",
  },
  {
    path: "/blog/least-frequently-used-cache",
  },
  {
    path: "/blog/bloom-filter",
  },
  {
    path: "/blog/n-ary-tree",
  },
  {
    path: "/blog/suffix-tree-data-structure",
  },
  {
    path: "/blog/introduction-to-dynamic-programming",
  },
  {
    path: "/blog/top-down-memoization-vs-bottom-up-tabulation",
  },
  {
    path: "/blog/dynamic-programming-vs-divide-and-conquer-approach",
  },
  {
    path: "/blog/what-common-problems-solved-using-dynamic-programming",
  },
  {
    path: "/blog/climbing-stairs-problem",
  },
  {
    path: "/blog/minimum-coin-change",
  },
  {
    path: "/blog/maximum-subarray-sum",
  },
  {
    path: "/blog/longest-common-subsequence",
  },
  {
    path: "/blog/minimum-number-of-jumps-to-reach-end",
  },
  {
    path: "/blog/zero-one-knapsack-problem",
  },
  {
    path: "/blog/edit-distance",
  },
  {
    path: "/blog/total-number-of-binary-search-trees-with-n-keys",
  },
  {
    path: "/blog/sorted-array-to-balanced-bst",
  },
  {
    path: "/blog/validate-binary-search-tree",
  },
  {
    path: "/blog/minimum-absolute-difference-in-bst",
  },
  {
    path: "/blog/kth-largest-element-in-bst",
  },
  {
    path: "/blog/merge-overlapping-intervals",
  },
  {
    path: "/blog/inversion-count-in-array",
  },
  {
    path: "/blog/flood-fill-problem",
  },
  {
    path: "/blog/find-all-combinations-of-k-numbers",
  },
  {
    path: "/blog/basics-of-system-design",
  },
  {
    path: "/blog/how-to-approach-system-design-interview",
  },
  {
    path: "/blog/network-protocols-concept",
  },
  {
    path: "/blog/process-management-in-operating-system",
  },
  {
    path: "/blog/latency-in-system-design",
  },
  {
    path: "/blog/throughput-in-system-design",
  },
  {
    path: "/blog/availability-system-design-concept",
  },
  {
    path: "/blog/distributed-system-in-system-design",
  },
  {
    path: "/blog/client-server-architecture",
  },
  {
    path: "/blog/peer-to-peer-networks",
  },
  {
    path: "/blog/load-balancers-in-system-design",
  },
  {
    path: "/blog/types-of-load-balancing-algorithms",
  },
  {
    path: "/blog/proxies-in-system-design",
  },
  {
    path: "/blog/web-sockets-in-system-design",
  },
  {
    path: "/blog/server-sent-events",
  },
  {
    path: "/blog/long-polling-in-system-design",
  },
  {
    path: "/blog/consistent-hashing-in-system-design",
  },
  {
    path: "/blog/throttling-and-rate-limiting",
  },
  {
    path: "/blog/publisher-subscriber-pattern",
  },
  {
    path: "/blog/leader-election-system-design",
  },
  {
    path: "/blog/caching-system-design-concept",
  },
  {
    path: "/blog/storage-and-redundancy",
  },
  {
    path: "/blog/data-partitioning-system-design-concept",
  },
  {
    path: "/blog/introduction-to-database-replication-system-design",
  },
  {
    path: "/blog/master-slave-replication-databases",
  },
  {
    path: "/blog/sql-vs-nosql",
  },
  {
    path: "/blog/cap-theorem-in-system-design",
  },
  {
    path: "/blog/key-value-stores-in-system-design",
  },
  {
    path: "/blog/graph-database-system-design",
  },
  {
    path: "/blog/implement-least-recently-used-cache",
  },
  {
    path: "/blog/least-frequently-used-cache",
  },
  {
    path: "/blog/bloom-filter",
  },
  {
    path: "/blog/design-key-value-store",
  },
  {
    path: "/blog/design-pastebin-system-design-interview-question",
  },
  {
    path: "/blog/design-typeahead-system",
  },
  {
    path: "/blog/design-a-url-shortening-service-like-tiny-url",
  },
  {
    path: "/blog/notification-service",
  },
  {
    path: "/blog/design-qr-code-system",
  },
  {
    path: "/blog/design-api-rate-limiter",
  },
  {
    path: "/blog/web-crawler",
  },
  {
    path: "/blog/design-youtube-system-design-interview-question",
  },
  {
    path: "/blog/design-whatsapp",
  },
  {
    path: "/blog/design-google-docs",
  },
  {
    path: "/blog/design-instagram",
  },
  {
    path: "/blog/design-twitter",
  },
  {
    path: "/blog/design-dropbox",
  },
  {
    path: "/blog/design-yelp",
  },
  {
    path: "/blog/design-uber",
  },
  {
    path: "/blog/why-should-we-learn-oops-concepts-in-programming",
  },
  {
    path: "/blog/principles-of-object-oriented-programming",
  },
  {
    path: "/blog/introduction-to-oops-concepts-in-cpp",
  },
  {
    path: "/blog/introduction-to-oops-in-java",
  },
  {
    path: "/blog/classe-and-object-in-oops",
  },
  {
    path: "/blog/constructors-in-java",
  },
  {
    path: "/blog/message-passing-oops",
  },
  {
    path: "/blog/encapsulation-in-oops",
  },
  {
    path: "/blog/abstraction-in-oops",
  },
  {
    path: "/blog/inheritance-in-java",
  },
  {
    path: "/blog/difference-between-compile-time-and-runtime-polymorphism",
  },
  {
    path: "/blog/method-overloading-in-java",
  },
  {
    path: "/blog/operator-overloading-in-cpp",
  },
  {
    path: "/blog/abstract-class-in-java",
  },
  {
    path: "/blog/interface-in-java",
  },
  {
    path: "/blog/principles-of-software-engineering",
  },
  {
    path: "/blog/cohesion-and-coupling-in-oops",
  },
  {
    path: "/blog/single-responsibility-principle-in-oops",
  },
  {
    path: "/blog/open-close-principle",
  },
  {
    path: "/blog/liskov-substitution-principle",
  },
  {
    path: "/blog/interface-segregation-principle",
  },
  {
    path: "/blog/dependency-inversion-principle",
  },
  {
    path: "/blog/factory-design-pattern",
  },
  {
    path: "/blog/abstract-factory-design-pattern",
  },
  {
    path: "/blog/singleton-design-pattern",
  },
  {
    path: "/blog/builder-design-pattern",
  },
  {
    path: "/blog/proxy-design-pattern",
  },
  {
    path: "/blog/facade-design-pattern-java",
  },
  {
    path: "/blog/observer-design-pattern",
  },
  {
    path: "/blog/strategy-design-pattern",
  },
  {
    path: "/blog/basic-python-for-ml-data-science",
  },
  {
    path: "/blog/python-tuples-lists-for-ml-data-science",
  },
  {
    path: "/blog/sets-and-dictionaries-in-python-for-ml",
  },
  {
    path: "/blog/conditions-and-branching-in-python",
  },
  {
    path: "/blog/loops-in-python-for-ml-data-science",
  },
  {
    path: "/blog/introduction-to-function-in-python",
  },
  {
    path: "/blog/introduction-to-oops-in-python",
  },
  {
    path: "/blog/introduction-to-numpy-in-python",
  },
  {
    path: "/blog/introduction-to-pandas",
  },
  {
    path: "/blog/introduction-to-machine-learning",
  },
  {
    path: "/blog/difference-between-ai-ml",
  },
  {
    path: "/blog/how-to-start-machine-learning-journey-a-step-by-step-guidance",
  },
  {
    path: "/blog/cost-function-in-machine-learning",
  },
  {
    path: "/blog/parameter-learning-and-gradient-descent-in-ml",
  },
  {
    path: "/blog/classification-of-machine-learning-models",
  },
  {
    path: "/blogs/supervised-unsupervised-and-semisupervised-learning",
  },
  {
    path: "/blogs/classification-and-regression-in-machine-learning",
  },
  {
    path: "/blog/loss-and-cost-functions-in-machine-learning",
  },
  {
    path: "/blog/make-system-ml-enabled",
  },
  {
    path: "/blog/introduction-to-matplotlib",
  },
  {
    path: "/blog/introduction-to-seaborn-library-in-python",
  },
  {
    path: "/blog/data-pre-processing-for-machine-learning",
  },
  {
    path: "/blog/structured-data-pre-processing-hands-on",
  },
  {
    path: "/blog/univariate-bivariate-multivariate-analysis",
  },
  {
    path: "/blog/text-data-pre-processing-techniques-in-ml",
  },
  {
    path: "/blog/word-vector-encoding-in-nlp",
  },
  {
    path: "/blog/feature-selection-techniques",
  },
  {
    path: "/blog/need-of-feature-scaling-in-machine-learning",
  },
  {
    path: "/blog/regularization-in-machine-learning",
  },
  {
    path: "/blog/bias-variance-tradeoff-in-machine-learning",
  },
  {
    path: "/blog/evaluation-metrics-regression-models",
  },
  {
    path: "/blog/evaluation-metrics-classification-models",
  },
  {
    path: "/blog/pathway-for-machine-learning-projects",
  },
  {
    path: "/blog/coding-ml-from-scratch",
  },
  {
    path: "/blog/introduction-to-scikit-learn",
  },
  {
    path: "/blog/linear-regression-in-machine-learning",
  },
  {
    path: "/blog/life-expectancy-prediction-using-linear-regression",
  },
  {
    path: "/blog/logistic-regression-in-ml",
  },
  {
    path: "/blog/optical-character-recognition-using-logistic-regression",
  },
  {
    path: "/blog/support-vector-machine-in-ml",
  },
  {
    path: "/blog/cancer-classification-using-machine-learning",
  },
  {
    path: "/blog/k-nearest-neighbours-in-ml",
  },
  {
    path: "/blog/email-spam-and-non-spam-filtering-using-machine-learning",
  },
  {
    path: "/blog/wine-quality-prediction",
  },
  {
    path: "/blog/naive-bayes-in-ml",
  },
  {
    path: "/blog/sentiment-analysis-using-machine-learning",
  },
  {
    path: "/blog/decision-tree-algorithm-in-ml",
  },
  {
    path: "/blog/boosting-and-its-variants",
  },
  {
    path: "/blog/random-forest-in-ml",
  },
  {
    path: "/blog/how-uber-use-machine-learning",
  },
  {
    path: "/blog/pubg-cheater-detection-system-using-ml",
  },
  {
    path: "/blog/xg-boost-algorithm-in-ml",
  },
  {
    path: "/blog/drug-discovery-using-ml",
  },
  {
    path: "/blog/k-means-clustering-algorithm",
  },
  {
    path: "/blog/personality-prediction-using-ml",
  },
  {
    path: "/blog/customer-segmentation-using-hierarchical-clustering",
  },
  {
    path: "/blog/principal-component-analysis-in-ml",
  },
  {
    path: "/blog/image-compression-using-pca",
  },
  {
    path: "/blog/tsne-algorithm-in-ml",
  },
]
